export const BASE_DATE_FORMAT = "DD/MM/YYYY";
export const DATE_FORMAT_WITH_SEPARATOR_REVERSED = "YYYY-MM-DD";
export const SHORT_DATE_FORMAT = "D MMM YYYY";
export const LONG_DATE_FORMAT = "dddd Do MMMM YYYY ";
export const DATE_FORMAT_WITH_DAY = "ddd, DD/MM/YY";
export const BASE_CURRENCY_FORMAT = "$0,0.00";
export const DATE_FORMAT_WITH_TIME = "DD/MM/YYYY hh:mm a";
export const API_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS";
export const LONG_DATE_FORMAT_WITH_TIME = "dddd, Do MMMM, hh:mm";
export const DATE_FORMAT = "ddd DD";
export const ENTRY_TIME_FORMAT = "HH:mm";
export const API_TIME_FORMAT = "HH:mm:ss";
export const EMPTY_TIME = "00:00:00";
export const DAY_HOURS = 24;
export const HOUR_MINUTES = 60;
export const MINUTE_SECONDS = 60;
export const SECOND_MILLISECONDS = 1000;
export const DAY_MINUTES = DAY_HOURS * HOUR_MINUTES;
export const DAY_MILLISECONDS =
  DAY_HOURS * HOUR_MINUTES * MINUTE_SECONDS * SECOND_MILLISECONDS;
