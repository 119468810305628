import { redirectWithPermissionCheck } from "@/router/index";
import { MAIN_NAVIGATION_ITEMS } from "@/router/constants";

const { WORKERS } = MAIN_NAVIGATION_ITEMS;

export const workersRouter = [
  {
    path: "/workers",
    name: WORKERS,
    component: () => import("@/views/workers/WorkerList.vue"),
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, WORKERS);
    }
  },
  {
    path: "/workers/:id",
    name: "workerInfo",
    redirect: "/workers/:id/profile",
    component: () => import("@/views/workers/WorkerDetails/WorkerInfo.vue"),
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, "workers.view");
    },
    children: [
      {
        path: "profile",
        name: "workerProfile",
        component: () => import("@/views/workers/WorkerDetails/WorkerProfile"),
        beforeEnter: (to, from, next) => {
          redirectWithPermissionCheck(next, "workers.view");
        }
      },
      {
        path: "activity",
        name: "workerActivity",
        component: () => import("@/views/workers/WorkerDetails/WorkerActivity"),
        beforeEnter: (to, from, next) => {
          redirectWithPermissionCheck(next, "workers.view");
        }
      }
    ]
  }
];
